import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import SideLine from "./sideLine";

function SegmentProduct(props) {
  const { t } = useTranslation();

  return (
    <>
      <section id={props.id}>
        <div className="container reveal">
          <div className="row">
            <div className="col-lg-1 d-none d-xs-none d-sm-none d-md-none d-lg-block">
              <SideLine label={false} height={"h-100"} />
            </div>

            <div className="col col-12 col-xs-12 col-sm-12 col-md-12 col-lg-11">
              <div
                className={`row my-5 ${
                  props.reverse ? "flex-row-reverse" : ""
                }`}
              >
                <div className="col col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="card h-100 rounded-4 p-2 d-flex justify-content-center">
                    <img
                      className="w-75 rounded-2 m-auto p-2"
                      src={props.img}
                      alt={""}
                    />
                  </div>
                </div>

                <div className="col col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6">
                  <h1 className="text-light">{props.title}</h1>
                  <p>{props.desc}</p>
                  <div className="d-flex justify-content-between mt-5">
                    <div>
                      <Link className="text-light" to={`/${props.link}`}>
                        <span>{t('see-more-btn')}</span>
                        <i
                          className={`bi mx-2 ${
                            document.documentElement.dir === "ltr"
                              ? "bi-arrow-up-right"
                              : "bi-arrow-up-left"
                          }`}
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SegmentProduct;
