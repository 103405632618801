import React from "react";

import curvedLineTop from "../assets/svg/curved-line-top.svg";
import curvedLineBot from "../assets/svg/curved-line-bot.svg";

function SideLine(props) {
  return (
    <>
      <div className={`d-flex ${props.height} align-items-center me-5'}`}>
        <div className={`vertical-line h-100 ${props.position}`}></div>
        {!props.label === false ? (
          <div className="position-absolute">
            <div
              className={
                localStorage.getItem("lang") === "fa"
                  ? "line-curved-top-rtl pe-2"
                  : "line-curved-top pe-2"
              }
            >
              <img src={curvedLineTop} alt={""} />
            </div>
            <div className="d-flex justify-content-end">
              <div className="my-2">
                <div className="bg-icon position-absolute"></div>
                <i
                  className={`bi ${props.iconClassName} text-light h5 mb-0`}
                ></i>
              </div>
            </div>
            <div
              className={
                localStorage.getItem("lang") === "fa"
                  ? "line-curved-bot-rtl pe-2"
                  : "line-curved-bot pe-2"
              }
            >
              <img src={curvedLineBot} alt={""} />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default SideLine;
