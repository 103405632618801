import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Slide } from "react-slideshow-image";
import { Link } from "react-router-dom";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

const slideImages = [
  {
    url: require(`../assets/svg/products/ils-ic.svg`).default,
    caption: "Libra-KDR",
    link: "kdr",
  },
  {
    url: require(`../assets/svg/products/academy-ic.svg`).default,
    caption: "Libra Academia",
    link: "academia",
  },
  {
    url: require(`../assets/svg/products/raadin-ic.svg`).default,
    caption: "Rodin",
    link: "rodin",
  },
  {
    url: require(`../assets/svg/products/ai-ic.svg`).default,
    caption: "Ai Department",
    link: "ai",
  },
];

function SideBar(props) {
  const { t } = useTranslation();
  const [shareUrl] = useState("https://yecomsoft.com/kdr");

  return (
    <>
      <div className="card p-4">
        <div className="row mb-5">
          <div className="col-12">
            <a
              href={props.link}
              className="btn btn-primary w-100"
            >
              {t('kdr-download-doc')}
            </a>
          </div>
        </div>

        <div className="row mb-5">
          <div className="col-12">
            <div className="text-muted mb-4">{t('kdr-other-products')}</div>
            <div className="slide-container">
              <Slide arrows={false} duration={2000}>
                {slideImages.map((slideImage, index) => (
                  <div className="each-slide px-2" key={index}>
                    <div className="d-flex justify-content-between">
                      <div>
                        <h5>{slideImage.caption}</h5>
                        <Link to={`/${slideImage.link}`}>
                          <span>{t('see-more-btn')}</span>
                          <i
                            className={`bi mx-2 ${
                              document.documentElement.dir === "ltr"
                                ? "bi-arrow-up-right"
                                : "bi-arrow-up-left"
                            }`}
                          />
                        </Link>
                      </div>
                      <img style={{ width: "64px" }} src={slideImage.url} alt={""} />
                    </div>
                  </div>
                ))}
              </Slide>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="text-muted mb-4">{t('kdr-share-friends')}</div>
          <div className="col-2">
            <EmailShareButton>
              <EmailIcon size={32} />
            </EmailShareButton>
          </div>
          <div className="col-2">
            <FacebookShareButton url={shareUrl}>
              <FacebookIcon size={32} />
            </FacebookShareButton>
          </div>
          <div className="col-2">
            <LinkedinShareButton url={shareUrl}>
              <LinkedinIcon size={32} />
            </LinkedinShareButton>
          </div>
          <div className="col-2">
            <TelegramShareButton url={shareUrl}>
              <TelegramIcon size={32} />
            </TelegramShareButton>
          </div>
          <div className="col-2">
            <TwitterShareButton url={shareUrl}>
              <TwitterIcon size={32} />
            </TwitterShareButton>
          </div>
          <div className="col-2">
            <WhatsappShareButton url={shareUrl}>
              <WhatsappIcon size={32} />
            </WhatsappShareButton>
          </div>
        </div>
      </div>
    </>
  );
}

export default SideBar;
