import { useTranslation } from "react-i18next";

import { JsonLd } from "react-schemaorg";

import SideBar from "../components/sideBar";

const professionalCashier = [
  "rodin-professional-cashier-list-1",
  "rodin-professional-cashier-list-2",
  "rodin-professional-cashier-list-3",
  "rodin-professional-cashier-list-4",
  "rodin-professional-cashier-list-5",
];

const productInformation = [
  "rodin-product-information-list-1",
  "rodin-product-information-list-2",
  "rodin-product-information-list-3",
  "rodin-product-information-list-4",
  "rodin-product-information-list-5",
  "rodin-product-information-list-6",
  "rodin-product-information-list-7",
  "rodin-product-information-list-8",
];

const financialSys = [
  "rodin-financial-system-list-1",
  "rodin-financial-system-list-2",
  "rodin-financial-system-list-3",
  "rodin-financial-system-list-4",
  "rodin-financial-system-list-5",
  "rodin-financial-system-list-6",
  "rodin-financial-system-list-7",
  "rodin-financial-system-list-8",
  "rodin-financial-system-list-9",
  "rodin-financial-system-list-10",
];

const customerClub = [
  "rodin-customer-club-list-1",
  "rodin-customer-club-list-2",
  "rodin-customer-club-list-3",
  "rodin-customer-club-list-4",
];

const centeralizedPurchased = [
  "rodin-centralized-purchase-list-1",
  "rodin-centralized-purchase-list-2",
  "rodin-centralized-purchase-list-3",
  "rodin-centralized-purchase-list-4",
  "rodin-centralized-purchase-list-5",
  "rodin-centralized-purchase-list-6",
  "rodin-centralized-purchase-list-7",
];

const cafe = [
  "rodin-cafe-list-1",
  "rodin-cafe-list-2",
  "rodin-cafe-list-3",
  "rodin-cafe-list-4",
];

const capillarySales = [
  "rodin-capillary-sales-list-1",
  "rodin-capillary-sales-list-2",
  "rodin-capillary-sales-list-3",
  "rodin-capillary-sales-list-4",
  "rodin-capillary-sales-list-5",
];

const store = [
  "rodin-Store-list-1",
  "rodin-Store-list-2",
  "rodin-Store-list-3",
  "rodin-Store-list-4",
  "rodin-Store-list-5",
];

export default function Rodin() {
  const { t } = useTranslation();

  return (
    <>
      <div style={{ marginTop: "10rem" }} className="text-light">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-9">
              <div className="row">
                <div className="col-12">
                  <h1>{t("rodin-introduction-title")}</h1>
                  <p>{t("rodin-introduction-desc-1")}</p>
                  <p>{t("rodin-introduction-desc-2")}</p>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <h3>{t("rodin-professional-cashier-title")}</h3>
                  <ul>
                    {professionalCashier.map((item, index) => (
                      <li key={item}>{t(item)}</li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <h3>{t("rodin-product-information-title")}</h3>
                  <ul>
                    {productInformation.map((item, index) => (
                      <li key={item}>{t(item)}</li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <h3>{t("rodin-financial-system-title")}</h3>
                  <ul>
                    {financialSys.map((item, index) => (
                      <li key={item}>{t(item)}</li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <h3>{t("rodin-customer-club-title")}</h3>
                  <ul>
                    {customerClub.map((item, index) => (
                      <li key={item}>{t(item)}</li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <h3>{t("rodin-centralized-purchase-title")}</h3>
                  <ul>
                    {centeralizedPurchased.map((item, index) => (
                      <li key={item}>{t(item)}</li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <h3>{t("rodin-cafe-title")}</h3>
                  <ul>
                    {cafe.map((item, index) => (
                      <li key={item}>{t(item)}</li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <h3>{t("rodin-capillary-sales-title")}</h3>
                  <ul>
                    {capillarySales.map((item, index) => (
                      <li key={item}>{t(item)}</li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <h3>{t("rodin-Store-title")}</h3>
                  <ul>
                    {store.map((item, index) => (
                      <li key={item}>{t(item)}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-3 d-none d-md-block">
              <SideBar />
            </div>
          </div>
        </div>
      </div>
      <JsonLd
        item={{
          "@context": "https://schema.org",
          "@type": "Product",
          description:
            "Rodin software has started its activity in the field of sales management and accounting of cultural stores since 2002 and has been developed with the vision of centering the process of executive routines, providing mechanized workflow and high compatibility when in the store.",
          name: "Rodin",
          "image": "https://yecomsoft.com/static/media/rodin.df94a4c02a8d28c0b062e049501a5a5d.svg",
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "5.0",
            "reviewCount": "10"
          },
          "review": [
            {
              "@type": "Review",
              "author": {
                "@type": "Person",
                "name": "Sepehr Rokni"
              },
              "datePublished": "2022-04-01",
              "reviewBody": "The Rodin platform is a wonderful product that make for a great jobs",
              "reviewRating": {
                "@type": "Rating",
                "bestRating": "5",
                "ratingValue": "1",
                "worstRating": "1"
              }
            },
            {
              "@type": "Review",
              "author": {
                "@type": "Person",
                "name": "Arman Kalhori"
              },
              "datePublished": "2022-04-15",
              "reviewBody": "Great library system management for the price. It is useful and best.",
              "name": "Value purchase",
              "reviewRating": {
                "@type": "Rating",
                "bestRating": "5",
                "ratingValue": "4",
                "worstRating": "1"
              }
            }
          ]
        }}
      />
    </>
  );
}
