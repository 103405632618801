import React from "react";
import { Trans, useTranslation } from "react-i18next";

import { JsonLd } from "react-schemaorg";

import SegmentProduct from "../components/segmentProduct";
import Welcome from "../components/welcome";

import AboutUs from "../components/aboutUs";
import ContactUs from "../components/contactUs";

import kdr from "../assets/svg/products/kdr.svg";
import rodin from "../assets/svg/products/rodin.svg";
import academia from "../assets/svg/products/academia.svg";
import ai from "../assets/svg/products/ai.svg";
import Testomonial from "../components/testomonial";

function Home() {
  const { t } = useTranslation();

  const products = [
    {
      title: t("segment-libra-kdr-title"),
      // desc: t('segment-libra-kdr-desc',{colorText: t(`segment-libra-kdr-desc-color-text-val`)}),
      // desc: <Trans i18nKey="segment-libra-kdr-desc" components={{ 1: <Trans i18nKey="segment-libra-kdr-desc-color-text-val" /> }} />,
      desc: (
        <Trans
          t={t}
          components={[<span className="coloring-p1 fw-bold"></span>]}
        >
          <p>
            {t("segment-libra-kdr-desc", {
              somethingInsideBadge: t("segment-libra-kdr-desc-color-text-val"),
            })}
          </p>
        </Trans>
      ),
      img: kdr,
      reverse: false,
      link: "kdr",
      id: "kdr-section",
    },
    {
      title: t("segment-academia-title"),
      desc: (
        <Trans
          t={t}
          components={[<span className="coloring-p2 fw-bold"></span>]}
        >
          <p>
            {t("segment-academia-desc", {
              somethingInsideBadge: t("segment-academia-desc-color-text-val"),
            })}
          </p>
        </Trans>
      ),
      img: academia,
      reverse: true,
      link: "academia",
      id: "academia-section",
    },
    {
      title: t("segment-rodin-title"),
      desc: (
        <Trans
          t={t}
          components={[<span className="coloring-p3 fw-bold"></span>]}
        >
          <p>
            {t("segment-rodin-desc", {
              somethingInsideBadge: t("segment-rodin-desc-color-text-val"),
            })}
          </p>
        </Trans>
      ),
      img: rodin,
      reverse: false,
      link: "rodin",
      id: "rodin-section",
    },
    {
      title: t("segment-ai-title"),
      desc: (
        <Trans
          t={t}
          components={[<span className="coloring-p4 fw-bold"></span>]}
        >
          <p>
            {t("segment-ai-desc", {
              somethingInsideBadge: t("segment-ai-desc-color-text-val"),
            })}
          </p>
        </Trans>
      ),
      img: ai,
      reverse: true,
      link: "ai",
      id: "ai-section",
    },
  ];

  return (
    <>
      <div className="margin-from-top">
        <Welcome />
        {products.map((item, index) => (
          <SegmentProduct
            key={index}
            id={item.id}
            title={item.title}
            desc={item.desc}
            img={item.img}
            reverse={item.reverse}
            link={item.link}
          />
        ))}

        <AboutUs />
        <Testomonial />
        <ContactUs />
      </div>
      <JsonLd
        item={{
          "@context": "https://schema.org",
          "@type": "Organization",
          "image": [
            "https://yecomsoft.com/static/media/logo-animated.1d8dbff768e2186db189dcff440b3746.svg",
           ],
          "name": "Yecomsoft",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "Vesal Shirazi",
            "addressLocality": "Tehran",
            "addressRegion": "TE",
            "postalCode": "12345",
            "addressCountry": "IR"
          },
          "review": {
            "@type": "Review",
            "reviewRating": {
              "@type": "Rating",
              "ratingValue": "4",
              "bestRating": "5"
            },
            "author": {
              "@type": "Person",
              "name": "Sepehr Rokni"
            }
          },
          "geo": {
            "@type": "GeoCoordinates",
            "latitude": 35.7038224,
            "longitude": 51.3990697
          },
          "url": "https://www.yecomsoft.com",
          "email": "info@yecomsoft.com",
          "telephone": "+982166957360",
          "servesCuisine": "Iranian",
          "priceRange": "$$$",
          "openingHoursSpecification": [
            {
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": [
                "Monday",
                "Tuesday"
              ],
              "opens": "11:30",
              "closes": "22:00"
            },
            {
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": [
                "Wednesday",
                "Thursday",
                "Friday"
              ],
              "opens": "11:30",
              "closes": "23:00"
            },
            {
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": "Saturday",
              "opens": "16:00",
              "closes": "23:00"
            },
            {
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": "Sunday",
              "opens": "16:00",
              "closes": "22:00"
            }
          ],
          alumni: [
            {
              "@type": "Person",
              name: "Davood Kiani",
            },
            {
              "@type": "Person",
              name: "Faryar Fatemi",
            },
            {
              "@type": "Person",
              name: "Farima Lari",
            },
            {
              "@type": "Person",
              name: "Erfan Mohammadi",
            },
            {
              "@type": "Person",
              name: "Sara Ghafouri",
            },
            {
              "@type": "Person",
              name: "Jacob Daneh",
            },
          ],
        }}
      />
      {/* <JsonLd
        item={{
          "@context": "https://schema.org",
          "image": "https://yecomsoft.com/static/media/logo-animated.1d8dbff768e2186db189dcff440b3746.svg",
          "@type": "Organization",
          address: {
            "@type": "PostalAddress",
            addressLocality: "Tehran, Iran",
            streetAddress: "Vesal Shirazi",
          },
          email: "info@yecomsoft.com",
          alumni: [
            {
              "@type": "Person",
              name: "Davood Kiani",
            },
            {
              "@type": "Person",
              name: "Faryar Fatemi",
            },
            {
              "@type": "Person",
              name: "Farima Lari",
            },
            {
              "@type": "Person",
              name: "Erfan Mohammadi",
            },
            {
              "@type": "Person",
              name: "Sara Ghafouri",
            },
            {
              "@type": "Person",
              name: "Jacob Daneh",
            },
          ],
          name: "Yecomsoft",
          telephone: "+98 21 6695 7360",
        }}
      /> */}
    </>
  );
}

export default Home;
