import { useTranslation } from "react-i18next";

import { JsonLd } from "react-schemaorg";

import ScreenMock from "../components/screenMock";
import SideBar from "../components/sideBar";

const keyFeaturesList = [
  "kdr-features-list-1",
  "kdr-features-list-2",
  "kdr-features-list-3",
  "kdr-features-list-4",
  "kdr-features-list-5",
  "kdr-features-list-6",
  "kdr-features-list-7",
  "kdr-features-list-8",
];

const systemModulesList = [
  { a: "kdr-system-modules-list-1", b: "kdr-system-modules-list-1b" },
  { a: "kdr-system-modules-list-2", b: "kdr-system-modules-list-2b" },
  { a: "kdr-system-modules-list-3", b: "kdr-system-modules-list-3b" },
  { a: "kdr-system-modules-list-4", b: "kdr-system-modules-list-4b" },
  { a: "kdr-system-modules-list-5", b: "kdr-system-modules-list-5b" },
  { a: "kdr-system-modules-list-6", b: "kdr-system-modules-list-6b" },
  { a: "kdr-system-modules-list-7", b: "kdr-system-modules-list-7b" },
  { a: "kdr-system-modules-list-8", b: "kdr-system-modules-list-8b" },
  { a: "kdr-system-modules-list-9", b: "kdr-system-modules-list-9b" },
  { a: "kdr-system-modules-list-10", b: "kdr-system-modules-list-10b" },
];

const standardsList = [
  { a: "kdr-Standards-list-1", b: "" },
  { a: "kdr-Standards-list-2", b: "" },
  { a: "kdr-Standards-list-3", b: "" },
  { a: "kdr-Standards-list-4", b: "" },
  {
    a: "kdr-Standards-list-5",
    b: ["kdr-Standards-list-1b", "kdr-Standards-list-2b"],
  },
  {
    a: "kdr-Standards-list-6",
    b: "",
  },
  { a: "kdr-Standards-list-7", b: "" },
  { a: "kdr-Standards-list-8", b: "" },
  { a: "kdr-Standards-list-9", b: "" },
  { a: "kdr-Standards-list-10", b: "" },
];

export default function KDR() {
  const { t } = useTranslation();

  return (
    <>
      <div style={{ marginTop: "10rem" }} className="text-light">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-9">
              <div className="row">
                <div className="col-12">
                  <h1>{t("kdr-introduction-title")}</h1>
                  <p>{t("kdr-introduction-desc")}</p>
                </div>
              </div>

              <div className="row">
                <ScreenMock />
                {/* <div className="col-12">
                <img
                  className="w-100"
                  src={require(`../assets/png/ils-web-assets-1.png`)}
                  alt={""}
                />
              </div> */}
              </div>

              <div className="row reveal">
                <div className="col-12">
                  <h3>{t("kdr-features-title")}</h3>
                  <ul>
                    {keyFeaturesList.map((item, index) => (
                      <li key={index}>{t(item)}</li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="row reveal">
                <div className="col-12">
                  <h3>{t("kdr-Standards-title")}</h3>
                  <ul>
                    {standardsList.map((item, index) => (
                      <li key={index}>
                        {t(item.a)}
                        {item.b === "" ? (
                          ""
                        ) : (
                          <ul>
                            <li>{t(item.b[0])}</li>
                            <li>{t(item.b[1])}</li>
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="row reveal">
                <div className="col-12">
                  <img
                    className="w-100"
                    src={require(`../assets/png/ils-web-assets-desktop-1.png`)}
                    alt={""}
                  />
                </div>
              </div>

              <div className="row reveal">
                <div className="col-12">
                  <h3>{t("kdr-system-modules-title")}</h3>
                  <ul>
                    {systemModulesList.map((item, index) => (
                      <li key={index}>
                        {t(item.a)}
                        <ul>
                          <li>{t(item.b)}</li>
                        </ul>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-3 d-none d-md-block">
              <SideBar link={"https://www.yecomsoft.com/kdr-brochure.pdf"} />
            </div>
          </div>
        </div>
      </div>
      <JsonLd
        item={{
          "@context": "https://schema.org",
          "@type": "Product",
          description:
            "LIBRA knowledge discovery and representation system (LIBRA-KDR) is a comprehensive and integrated management system of all kinds of information resources with a context-oriented approach and based on a modular architecture model (modularity), with the aim of meeting the various needs of all kinds of libraries, information centers and others.",
          name: "LIBRA-KDR",
          "image": "https://yecomsoft.com/static/media/kdr.16edd385dde70826a50053694430eab3.svg",
          "offers": {
            "@type": "Offer",
            "availability": "https://schema.org/InStock",
            "price": "55.00",
            "priceCurrency": "USD"
          },
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "5.0",
            "reviewCount": "10"
          },
          "review": [
            {
              "@type": "Review",
              "author": {
                "@type": "Person",
                "name": "Sepehr Rokni"
              },
              "datePublished": "2022-04-01",
              "reviewBody": "The Kdr-Libra is a wonderful product that make for a great jobs",
              "reviewRating": {
                "@type": "Rating",
                "bestRating": "5",
                "ratingValue": "1",
                "worstRating": "1"
              }
            },
            {
              "@type": "Review",
              "author": {
                "@type": "Person",
                "name": "Arman Kalhori"
              },
              "datePublished": "2022-04-15",
              "reviewBody": "Great library system management for the price. It is useful and best.",
              "name": "Value purchase",
              "reviewRating": {
                "@type": "Rating",
                "bestRating": "5",
                "ratingValue": "4",
                "worstRating": "1"
              }
            }
          ]
        }}
      />
    </>
  );
}
