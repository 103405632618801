import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import SideLine from "./sideLine";

function Footer() {
  const { t } = useTranslation();

  const d = new Date()
  const year = d.getFullYear();

  function backgroundImageFooter() {
    let elem = document.getElementById("bg-img-02");
    let scrollY = window.scrollY / 80;
    elem.style.backgroundSize = 100 + scrollY + "%";
  }
  window.addEventListener("scroll", backgroundImageFooter);

  const location = useLocation();

  return (
    <>
      <div className="position-relative">
        <section
          id="bg-img-02"
          className="position-absolute w-100 reveal bottom-image-background"
          style={{ bottom: "0" }}
        ></section>
        <div className="container">
          <div className="row reveal">
            {location.pathname === "/" ? (
              <>
                <div className="col-lg-1 d-none d-xs-none d-sm-none d-md-none d-lg-block">
                  <SideLine
                    iconClassName={"bi-send"}
                    label={false}
                    height={"h-100"}
                    position={"vertical-line-gradient-e"}
                  />
                </div>
                <div className="col col-12 col-xs-12 col-sm-12 col-md-12 col-lg-11">
                  <div className="d-flex justify-content-center h-100 py-4">
                    <small className="mt-4 text-light">
                      {t("footer-copy", { year: year })}
                    </small>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="col col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="d-flex justify-content-center h-100 py-4">
                    <small className="mt-4 text-light">
                    {t("footer-copy", { year: year })}
                    </small>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
