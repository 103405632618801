import React from "react";
import { useTranslation } from "react-i18next";
import { HashLink as Link } from 'react-router-hash-link';

import { Fade } from "react-slideshow-image";

import ilsIcon from "../assets/svg/products/ils-ic.svg";
import academyIcon from "../assets/svg/products/academy-ic.svg";
import rodinIcon from "../assets/svg/products/raadin-ic.svg";
import aiIcon from "../assets/svg/products/ai-ic.svg";

function SegmentBody() {
  const { t } = useTranslation();

  const slideImages = [
    {
      title: t("slide-show-title-01"),
      desc: t("slide-show-desc-01"),
      icon: "bi-palette",
    },
    {
      title: t("slide-show-title-02"),
      desc: t("slide-show-desc-02"),
      icon: "bi-chat-left-heart",
    },
    {
      title: t("slide-show-title-03"),
      desc: t("slide-show-desc-03"),
      icon: "bi-flag",
    },
  ];

  const productList = [
    {
      title: t("welcome-libra-kdr"),
      icon: ilsIcon,
      link: "#kdr-section",
    },
    {
      title: t("welcome-academia"),
      icon: academyIcon,
      link: "#academia-section",
    },
    {
      title: t("welcome-rodin"),
      icon: rodinIcon,
      link: "#rodin-section",
    },
    {
      title: t("welcome-ai"),
      icon: aiIcon,
      link: "#ai-section",
    },
  ];

  const scrollWithOffset = (el, offset) => {
    const elementPosition = el.offsetTop - offset;
    window.scroll({
      top: elementPosition,
      left: 0,
      behavior: "smooth"
    });  
  }
  return (
    <>
      <div className="row">
        <div className="col-12 my-4">
          <div className="slide-container">
            <div className="card d-flex w-100 bg-dark p-4 text-light welcome-card">
              <Fade arrows={false} duration={2000}>
                {slideImages.map((item, index) => (
                  <div key={index} className="each-fade big-cover-box">
                    <div
                      className={`d-flex justify-content-between ${
                        localStorage.getItem("lang") === "fa" ? "rtl" : ""
                      }`}
                    >
                      <div>
                        <h1>{item.title}</h1>
                        <p>{item.desc}</p>
                      </div>
                      <div className="d-none d-sm-none d-md-none d-lg-block">
                        <i className={`bi ${item.icon} welcome-icon-lg`}></i>
                      </div>
                    </div>
                  </div>
                ))}
              </Fade>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="row">
            {productList.map((item, index) => (
              <div
                key={index}
                className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-3 mb-4"
              >
                <Link
                  className="nav-link"
                  to={`/${item.link}`}
                  scroll={el => scrollWithOffset(el, 100)}
                >
                  <div className="card p-3 d-flex justify-content-center w-100 text-light product-card">
                    <img
                      className="w-50 m-auto my-4"
                      src={item.icon}
                      alt={item.title}
                    />
                    <h6 className="text-center">{item.title}</h6>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default SegmentBody;
