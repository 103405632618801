import { useTranslation } from "react-i18next";
import { Slide } from "react-slideshow-image";
// import HoverImage from "react-hover-image";

import { JsonLd } from "react-schemaorg";

import SideBar from "../components/sideBar";

import armenia from "../assets/jpg/armenia-img.jpg";
import beirut from "../assets/jpg/beirut-img.jpg";
import georgia from "../assets/jpg/georgia-img.jpg";
import yerevan from "../assets/jpg/yerevan-img.jpg";

const slideImages = [
  {
    image: armenia,
    caption: "Yerevan state university",
  },
  {
    image: beirut,
  },
  {
    image: georgia,
  },
  {
    image: yerevan,
  },
];

const responsiveSettings = [
  {
    breakpoint: 768,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 360,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
    },
  },
];

const institutions = [
  "academia-institutions-list-1",
  "academia-institutions-list-2",
  "academia-institutions-list-3",
  "academia-institutions-list-4",
  "academia-institutions-list-5",
  "academia-institutions-list-6",
  "academia-institutions-list-7",
  "academia-institutions-list-8",
  "academia-institutions-list-9",
  "academia-institutions-list-10",
  "academia-institutions-list-11",
];

const publishers = ["Yerevan", "Georgia", "AUB", "AUA"];

export default function Academia() {
  const { t } = useTranslation();

  return (
    <>
      <div style={{ marginTop: "10rem" }} className="text-light">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-9">
              <div className="row">
                <div className="col-12">
                  <h1>{t("academia-introduction-title")}</h1>
                  <p>{t("academia-introduction-desc-1")}</p>
                  <p>{t("academia-introduction-desc-2")}</p>
                  <p>{t("academia-introduction-desc-3")}</p>
                </div>
              </div>

              <div className="row my-5">
                <div className="row">
                  {publishers.map((item, index) => (
                    <div className="col-6 col-md-6 col-lg-3 m-auto">
                      <img
                        key={index}
                        src={require(`../assets/svg/brands/${item}.svg`)}
                        className="m-3 align-middle"
                        style={{ width: "160px" }}
                      />
                      {/* <HoverImage
                    key={index}
                    style={{ width: "160px" }}
                    className="m-3 align-middle"
                    src={require(`../assets/svg/brands/${item}.svg`)}
                    hoverSrc={require(`../assets/svg/brands/${item}-1.svg`)}
                  /> */}
                    </div>
                  ))}
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <h3>{t("academia-institutions-title")}</h3>
                  <ul>
                    {institutions.map((item, index) => (
                      <li key={index}>{t(item)}</li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <p>{t("academia-institutions-desc-1")}</p>
                  <p>{t("academia-institutions-desc-2")}</p>
                </div>
              </div>
            </div>

            <div className="col-3 d-none d-md-block">
              <SideBar />
            </div>
          </div>
        </div>
      </div>
      <JsonLd
        item={{
          "@context": "https://schema.org",
          "@type": "Product",
          description:
            "Libra Academia is the result of 15 years of useful work experience with the best publishers and international information providers in the publishing and research industry.",
          name: 'Libra Academia',
          "image": "https://yecomsoft.com/static/media/academia.9a5ae180608a1e432f69b19f2445546a.svg",
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "5.0",
            "reviewCount": "10"
          },
          "review": [
            {
              "@type": "Review",
              "author": {
                "@type": "Person",
                "name": "Sepehr Rokni"
              },
              "datePublished": "2022-04-01",
              "reviewBody": "The Libra Academia is a wonderful product that make for a great jobs",
              "reviewRating": {
                "@type": "Rating",
                "bestRating": "5",
                "ratingValue": "1",
                "worstRating": "1"
              }
            },
            {
              "@type": "Review",
              "author": {
                "@type": "Person",
                "name": "Arman Kalhori"
              },
              "datePublished": "2022-04-15",
              "reviewBody": "Great library system management for the price. It is useful and best.",
              "name": "Value purchase",
              "reviewRating": {
                "@type": "Rating",
                "bestRating": "5",
                "ratingValue": "4",
                "worstRating": "1"
              }
            }
          ]
        }}
      />
    </>
  );
}
