import React from "react";
import { useTranslation } from "react-i18next";
import CountUp from "react-countup";

import SideLine from "./sideLine";
import SegmentHeader from "./segmentHeader";
import SegmentBody from "./segmentBody";
import SegmentFooter from "./segmentFooter";

function Welcome() {
  const { t } = useTranslation();

  function backgroundImageWelcome() {
    let elem = document.getElementById("bg-img-01");
    let scrollY = window.scrollY / 30;
    elem.style.backgroundSize = 100 + scrollY + "%";
  }
  window.addEventListener("scroll", backgroundImageWelcome);

  return (
    <>
      <section
        id="bg-img-01"
        className="position-absolute w-100 section-100 top-image-background"
      ></section>

      <section id="product-and-services">
        <div className="container">
          <div className="row">
            <div className="col-lg-1 d-none d-xs-none d-sm-none d-md-none d-lg-block">
              <SideLine
                iconClassName={"bi-code"}
                label={true}
                height={"h-100vh"}
                position={"vertical-line-gradient-s"}
              />
            </div>
            <div className="col col-12 col-xs-12 col-sm-12 col-md-12 col-lg-11">
              <div className="row align-items-center h-100">
                <div className="col-12">
                  <div className="row">
                    <div className="col col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4">
                      <SegmentHeader
                        title={t("product-and-services-title")}
                        desc={t("product-and-services-desc")}
                      />
                      <div className="mt-5 py-4 d-flex text-light" role="alert">
                        <i className="bi bi-chat-right-quote mx-2"></i>
                        <p>{t("total-number-products")}</p>
                        <h4 className="mx-2">
                          <CountUp enableScrollSpy={true} end={4} />
                        </h4>
                      </div>
                    </div>
                    <div className="col col-12 col-xs-12 col-sm-12 col-md-8 col-lg-8">
                      <SegmentBody />
                    </div>
                  </div>
                  {/* <div className="row mt-5">
                    <div className="col col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <SegmentFooter />
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Welcome;
