import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './i18n';

import "./scss/style.scss"
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap-icons/font/bootstrap-icons.css";
import "react-slideshow-image/dist/styles.css";

import Root from "./routes/root";
import ErrorPage from "./error-page";
import Home from './routes/home';
import KDR from './routes/kdr';
import Academia from './routes/academia';
import Rodin from './routes/rodin';
import Ai from './routes/ai';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "kdr",
        element: <KDR />,
      },
      {
        path: "academia",
        element: <Academia />,
      },
      {
        path: "rodin",
        element: <Rodin />,
      },
      {
        path: "ai",
        element: <Ai />,
      },
    ],
  },
]);


ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);