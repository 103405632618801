import { useTranslation } from "react-i18next";

import { JsonLd } from "react-schemaorg";

import SideBar from "../components/sideBar";

export default function Ai() {
  const { t } = useTranslation();

  return (
    <>
      <div style={{ marginTop: "10rem" }} className="text-light">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-9">
              <div className="row">
                <div className="col-12">
                  <h1>{t("ai-introduction-title")}</h1>
                  <p>{t("ai-introduction-desc")}</p>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <h3>{t("ai-products-title")}</h3>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <h5>{t("ai-products-shiva-title")}</h5>
                  <p>{t("ai-products-shiva-desc")}</p>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <h5>{t("ai-products-recommender-title")}</h5>
                  <p>{t("ai-products-recommender-desc")}</p>
                </div>
              </div>
            </div>
            <div className="col-3 d-none d-md-block">
              <SideBar />
            </div>
          </div>
        </div>
      </div>
      <JsonLd
        item={{
          "@context": "https://schema.org",
          "@type": "ItemList",
          numberOfItems: "315",
          description:
            "Yecomsoft artificial intelligence unit researches and develops new artificial intelligence algorithms, and implements and changes existing algorithms.",
          name: "Department of artificial intelligence",
          "image": "https://yecomsoft.com/static/media/ai.cbabef61e1814f530f588a4a27fe03f3.svg",
          itemListElement: [
            {
              "@type": "Product",
              name: "Shiva",
              description: "Yecomsoft text-to-speech engine, which takes the raw text, reads it with a voice close to the human voice, and converts it into an audio file. For this engine, several modules have been used in the field of Persian text processing, text-to-phoneme conversion, and phoneme-to-voice conversion.",
              "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "5.0",
                "reviewCount": "10"
              },
              "review": [
                {
                  "@type": "Review",
                  "author": {
                    "@type": "Person",
                    "name": "Sepehr Rokni"
                  },
                  "datePublished": "2022-04-01",
                  "reviewBody": "The Kdr-Libra is a wonderful product that make for a great jobs",
                  "reviewRating": {
                    "@type": "Rating",
                    "bestRating": "5",
                    "ratingValue": "1",
                    "worstRating": "1"
                  }
                },
                {
                  "@type": "Review",
                  "author": {
                    "@type": "Person",
                    "name": "Arman Kalhori"
                  },
                  "datePublished": "2022-04-15",
                  "reviewBody": "Great library system management for the price. It is useful and best.",
                  "name": "Value purchase",
                  "reviewRating": {
                    "@type": "Rating",
                    "bestRating": "5",
                    "ratingValue": "4",
                    "worstRating": "1"
                  }
                }
              ]
            },
            {
              "@type": "Product",
              name: "Recommender system",
              description: "In this system, which is used to recommend items to users, the previous methods are improved, and the work of recommending items is done in a personalized way, in such a way that for each user, based on the time, the user's interests, the characteristics of the items, and the trends in the Market, the desired items are suggested.",
              "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "5.0",
                "reviewCount": "10"
              },
              "review": [
                {
                  "@type": "Review",
                  "author": {
                    "@type": "Person",
                    "name": "Sepehr Rokni"
                  },
                  "datePublished": "2022-04-01",
                  "reviewBody": "The Recommender system is a wonderful product that make for a great jobs",
                  "reviewRating": {
                    "@type": "Rating",
                    "bestRating": "5",
                    "ratingValue": "1",
                    "worstRating": "1"
                  }
                },
                {
                  "@type": "Review",
                  "author": {
                    "@type": "Person",
                    "name": "Arman Kalhori"
                  },
                  "datePublished": "2022-04-15",
                  "reviewBody": "Great Recommender system for the Knowledge. It is useful and best.",
                  "name": "Value purchase",
                  "reviewRating": {
                    "@type": "Rating",
                    "bestRating": "5",
                    "ratingValue": "4",
                    "worstRating": "1"
                  }
                }
              ]
            },
          ],
        }}
      />
    </>
  );
}
