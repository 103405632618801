import React from "react";
import SideLine from "./sideLine";
import { useTranslation } from "react-i18next";

import { LinkedinIcon, LinkedinShareButton } from "react-share";

import profileImgMan01 from "../assets/svg/profile-placeholder-man-01.svg";
import profileImgMan02 from "../assets/svg/profile-placeholder-man-02.svg";
import profileImgWoman01 from "../assets/svg/profile-placeholder-woman-01.svg";

const responsiveSettings = [
  {
    breakpoint: 768,
    settings: {
      slidesToShow: 4,
      slidesToScroll: false,
    },
  },
  {
    breakpoint: 360,
    settings: {
      slidesToShow: 1,
      slidesToScroll: false,
    },
  },
];

function Testomonial() {
  const { t } = useTranslation();

  const slideImages = [
    {
      image: profileImgMan01,
      name: t("testomonial-davood-name"),
      title: t("testomonial-davood-title"),
      desc: t("testomonial-davood-desc"),
      urlLinkedin: "https://www.linkedin.com/in/davood-kiani-095171103/",
    },
    {
      image: profileImgMan02,
      name: t("testomonial-faryar-name"),
      title: t("testomonial-faryar-title"),
      desc: t("testomonial-faryar-desc"),
      urlLinkedin: "https://www.linkedin.com/in/faryar-fatemi-05601024/",
    },
    {
      image: profileImgWoman01,
      name: t("testomonial-farima-name"),
      title: t("testomonial-farima-title"),
      desc: t("testomonial-farima-desc"),
      urlLinkedin: "https://www.linkedin.com/in/farimalari/",
    },
    {
      image: profileImgMan02,
      name: t("testomonial-erfan-name"),
      title: t("testomonial-erfan-title"),
      desc: t("testomonial-erfan-desc"),
      urlLinkedin: "https://www.linkedin.com/in/erfan-mohammadi-1b5ba75b/",
    },
    {
      image: profileImgWoman01,
      name: t("testomonial-sara-name"),
      title: t("testomonial-sara-title"),
      desc: t("testomonial-sara-desc"),
      urlLinkedin: "https://www.linkedin.com/in/erfan-mohammadi-1b5ba75b/",
    },
    {
      image: profileImgMan02,
      name: t("testomonial-jacob-name"),
      title: t("testomonial-jacob-title"),
      desc: t("testomonial-jacob-desc"),
      urlLinkedin: "https://www.linkedin.com/in/erfan-mohammadi-1b5ba75b/",
    },
  ];

  return (
    <>
      <section>
        <div className="container reveal">
          <div className="row text-light">
            <div className="col-lg-1 d-none d-xs-none d-sm-none d-md-none d-lg-block">
              <SideLine
                iconClassName={"bi-send"}
                label={false}
                height={"h-100"}
              />
            </div>
            <div className="col col-12 col-xs-12 col-sm-12 col-md-12 col-lg-11">
              <div className="my-4">
                <h2>{t("testomonial-title")}</h2>
              </div>
              <div className="row">
                {slideImages.map((slideImage, index) => (
                  <div className=" col-3 each-slide p-5" key={index}>
                    <div className="w-100 d-flex justify-content-end">
                      <i
                        className="bi bi-quote opacity-25"
                        style={{ fontSize: "3rem", marginBottom: -40 }}
                      ></i>
                    </div>
                    <div>
                      <div className="d-flex justify-content-between opacity-75">
                        {/* <a href={slideImage.urlLinkedin} target="blanck"> */}
                        <img
                          src={slideImage.image}
                          className="rounded-circle profile-img h-100"
                          alt={slideImage.name}
                        />
                        {/* </a>e */}
                        {/* <h6 className="mt-4">{slideImage.name}</h6> */}
                      </div>
                      <div className="mt-3">
                        <small style={{ fontSize: 12 }} className="opacity-50">
                          {slideImage.title}
                        </small>
                        <p>{slideImage.name}</p>
                        {/* <p>{slideImage.desc}</p> */}
                      </div>
                      <div className="col-2">
                        {/* <LinkedinShareButton url={slideImage.urlLinkedin}>
                          <LinkedinIcon size={32} />
                        </LinkedinShareButton> */}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Testomonial;
